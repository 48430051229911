<template>
  <asterix-section :status="sectionStatus" :is-loading="isLoadingSection">
    <template #header-right>
      <div>
        <sun-date-picker-v2
          id="analytics-filter-date-range"
          :key="datepickerKey"
          ref="datePicker"
          :value="quickFiltersDate"
          :max-date="limitDateRange.max"
          no-header
          no-calendar-icon
          :range="true"
          show-date-list
          :date-list="dateList"
          @change="onQuickFiltersDate($event.value)"
          @select:date-list-option="selectDateOption"
        />
      </div>
    </template>
    <template #content>
      <ingestion-job-notifications
        v-if="activeClientId"
        :service="getIngestionJobNotifications"
        :client-id="activeClientId"
      />
      <analytics-section
        header="Open Market Web"
        :loading-kpis="isLoadingMarketKpis"
        :kpis-data="marketkpisData"
        :chart-loading="!marketChartLoading"
        :chart-options="chartOptions"
        :chart-series="marketChartSeries"
        :quick-filters-date="quickFiltersDate"
        :chart-yaxis="chartYaxis"
        :chart-type-selected="chartTypeSelected"
        :chart-revenue="chartRevenue"
        :has-filters="hasMarketFilters"
        :report-options="reportOptions"
        :is-loading-table="isLoadingMarketTable"
        table-key="Deal_OTT_table"
        :table-columns-definition="tableColumnsDefinition"
        :column-types="columnTypes"
        :auto-group-column-def="autoGroupColumnDef"
        :row-model-type="rowModelType"
        :default-col-def="defaultColDef"
        :is-loading-totals="isLoadingMarketTotals"
        :totals-column-names="totalsColumnNames"
        :totals-column="totalsColumn"
        :totals="marketTotals"
        @chartTypeChanged="onChartTypeChange"
        @resetFilters="resetMarketFilters"
        @handlerReport="handlerReportOpenMarket"
        @gridReady="onMarketGridReady"
        @changeFilters="updateMarketActiveFilters"
      />
      <analytics-section
        header="Deals WEB"
        :loading-kpis="isLoadingDealsKpis"
        :kpis-data="dealsKpisData"
        :chart-loading="!dealChartLoading"
        :chart-options="chartOptions"
        :chart-series="dealChartSeries"
        :quick-filters-date="quickFiltersDate"
        :chart-yaxis="chartYaxis"
        :chart-type-selected="chartTypeSelected"
        :chart-revenue="chartRevenue"
        :has-filters="hasDealFilters"
        :report-options="reportOptions"
        :is-loading-table="isLoadingDealTable"
        table-key="Deal_table"
        :table-columns-definition="tableColumnsDefinition"
        :column-types="columnTypes"
        :auto-group-column-def="autoGroupColumnDef"
        :row-model-type="rowModelType"
        :default-col-def="defaultColDef"
        :is-loading-totals="isLoadingDealTotals"
        :totals-column-names="totalsColumnNames"
        :totals-column="totalsColumn"
        :totals="dealTotals"
        @chartTypeChanged="onChartTypeChange"
        @resetFilters="resetDealFilters"
        @handlerReport="handlerReportDeal"
        @gridReady="onDealGridReady"
        @changeFilters="updateDealActiveFilters"
      />
      <analytics-section
        header="Deals OTT"
        :loading-kpis="isLoadingDealsOttKpis"
        :kpis-data="dealsOttKpisData"
        :chart-loading="!dealChartOttLoading"
        :chart-options="chartOptions"
        :chart-series="dealOttChartSeries"
        :quick-filters-date="quickFiltersDate"
        :chart-yaxis="chartYaxis"
        :chart-type-selected="chartTypeSelected"
        :chart-revenue="chartRevenue"
        :has-filters="hasDealOttFilters"
        :report-options="reportOptions"
        :is-loading-table="isLoadingDealOttTable"
        table-key="Deal_OTT_table"
        :table-columns-definition="tableColumnsDefinition"
        :column-types="columnTypes"
        :auto-group-column-def="autoGroupColumnDef"
        :row-model-type="rowModelType"
        :default-col-def="defaultColDef"
        :is-loading-totals="isLoadingDealOttTotals"
        :totals-column-names="totalsColumnNames"
        :totals-column="totalsColumn"
        :totals="dealOttTotals"
        @chartTypeChanged="onChartTypeChange"
        @resetFilters="resetDealOttFilters"
        @handlerReport="handlerReportDealOtt"
        @gridReady="onDealOttGridReady"
        @changeFilters="updateDealOttActiveFilters"
      />
      <analytics-section
        header="Markets"
        :loading-kpis="isLoadingMarketsKpis"
        :kpis-data="marketsKpisData"
        :chart-loading="!marketsChartLoading"
        :chart-options="chartOptions"
        :chart-series="marketsChartSeries"
        :quick-filters-date="quickFiltersDate"
        :chart-yaxis="chartYaxis"
        :chart-type-selected="chartTypeSelected"
        :chart-revenue="chartRevenue"
        :has-filters="hasMarketsFilters"
        :report-options="reportOptions"
        :is-loading-table="isLoadingMarketsTable"
        table-key="Markets_table"
        :table-columns-definition="tableColumnsDefinition"
        :column-types="columnTypes"
        :auto-group-column-def="autoGroupColumnDef"
        :row-model-type="rowModelType"
        :default-col-def="defaultColDef"
        :is-loading-totals="isLoadingMarketsTotals"
        :totals-column-names="totalsColumnNames"
        :totals-column="totalsColumn"
        :totals="marketsTotals"
        @chartTypeChanged="onChartTypeChange"
        @resetFilters="resetMarketsFilters"
        @handlerReport="handlerReportMarkets"
        @gridReady="onMarketsGridReady"
        @changeFilters="updateMarketsActiveFilters"
      />
      <analytics-section
        header="Skin"
        :loading-kpis="isLoadingSkinKpis"
        :kpis-data="skinKpisData"
        :chart-loading="!skinChartLoading"
        :chart-options="chartOptions"
        :chart-series="skinChartSeries"
        :quick-filters-date="quickFiltersDate"
        :chart-yaxis="chartYaxis"
        :chart-type-selected="chartTypeSelected"
        :chart-revenue="chartRevenue"
        :has-filters="hasSkinFilters"
        :report-options="reportOptions"
        :is-loading-table="isLoadingSkinTable"
        table-key="Skin_table"
        :table-columns-definition="tableColumnsDefinition"
        :column-types="columnTypes"
        :auto-group-column-def="autoGroupColumnDef"
        :row-model-type="rowModelType"
        :default-col-def="defaultColDef"
        :is-loading-totals="isLoadingSkinTotals"
        :totals-column-names="totalsColumnNames"
        :totals-column="totalsColumn"
        :totals="skinTotals"
        @chartTypeChanged="onChartTypeChange"
        @resetFilters="resetSkinFilters"
        @handlerReport="handlerReportSkin"
        @gridReady="onSkinGridReady"
        @changeFilters="updateSkinActiveFilters"
      />
    </template>
  </asterix-section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { addMonths } from 'date-fns';
import { CONFIG } from './config';
import { BREAKDOWN_COLUMNS, COLUMNS, CUSTOM_OPTION } from '../config';
import {
  getAnalyticsDealKpis,
  getAnalyticsDealOttKpis,
  getAnalyticsDealOttTotals,
  getAnalyticsDealTotals,
  getAnalyticsMarketKpis,
  getAnalyticsMarketsChart,
  getAnalyticsMarketsKpis,
  getAnalyticsMarketsTotals,
  getAnalyticsMarketTotals,
  getDealChartV2,
  getDealOttChartV2,
  getIngestionJobNotifications,
  getMarketChartV2,
  getTableV2,
  getAnalyticsSkinTotals,
  getAnalyticsSkinChart,
  getAnalyticsSkinKpis,
} from '@/services/modules/dashboardSSP/analytics';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import { ANALYTICS_REPORT_TOAST, SET_ANALYTICS_REPORT_TOAST } from '@/store/modules/analyticsReportToast/keys';
import { BREAKDOWN, SAVE_BREAKDOWN } from '@/store/modules/filters/keys';
import { BREAKDOWN_VALUES } from '@/model/modules/dashboardSSP/analytics/BreakdownValues';
import { CONTEXTS } from '@/model/shared/contexts';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { indexMixin } from '@/mixins/index/indexMixin';
import { queryParamsMixin } from '@/mixins/common/queryParamsMixin';
import { SECTION_STATUS } from '@/model/shared/sectionStatus';
import { dateToYYYYMMDD, stringToDate } from '@/filters/dateFilters';
import { Toast } from '@/model/shared/Toast';
import { USER } from '@/store/modules/auth/keys';
import ApiRequest from '@/utils/apiRequest';
import AsterixSection from '@/components/templates/AsterixSection';
import Breakdown from '@/model/shared/Breakdown';
import filtersMixin from '@/mixins/filters/filtersMixin';
import DetailPagination from '@/model/shared/analytics/DetailPagination';
import { getAllConnectedPlatforms } from '@/services/modules/dashboardSSP/platform';
import AnalyticsCellOrchestrator from '@/components/organisms/modules/dashboardSSP/AnalyticsCellOrchestrator.vue';
import { deepClone } from '@/utils/deepClone';
import IngestionJobNotifications from '@/components/molecules/shared/IngestionJobNotifications.vue';
import AnalyticsSection from '@/components/organisms/modules/dashboardSSP/analytics/AnalyticsSection.vue';
import SunDatePickerV2 from '@/components/atoms/SunDatePickerV2/SunDatePickerV2.vue';

function thousandSeparator(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export default {
  name: 'Economics',
  components: {
    SunDatePickerV2,
    AsterixSection,
    IngestionJobNotifications,
    AnalyticsSection,
    // eslint-disable-next-line vue/no-unused-components
    AnalyticsCellOrchestrator,
    // AsterixTableRowLoadMore: () => import('@/components/molecules/shared/AsterixTableRowLoadMore'),
  },
  mixins: [
    queryParamsMixin,
    filtersMixin({
      filters: CONFIG.filters,
      filterQuick: {
        range_start: undefined,
        range_end: undefined,
      },
    }),
    indexMixin,
  ],
  data: () => ({
    chartYaxis: CONFIG.yaxis,
    marketChartLoading: true,
    dealChartLoading: true,
    dealChartOttLoading: true,
    marketsChartLoading: true,
    skinChartLoading: true,
    marketChartData: {
      [CONFIG.chartDataType.BYPLATFORM]: new Map(),
      [CONFIG.chartDataType.REVENUE]: new Map(),
    },
    dealChartData: {
      [CONFIG.chartDataType.BYPLATFORM]: new Map(),
      [CONFIG.chartDataType.REVENUE]: new Map(),
    },
    dealOttChartData: {
      [CONFIG.chartDataType.BYPLATFORM]: new Map(),
      [CONFIG.chartDataType.REVENUE]: new Map(),
    },
    marketsChartData: {
      [CONFIG.chartDataType.BYPLATFORM]: new Map(),
      [CONFIG.chartDataType.REVENUE]: new Map(),
    },
    skinChartData: {
      [CONFIG.chartDataType.BYPLATFORM]: new Map(),
      [CONFIG.chartDataType.REVENUE]: new Map(),
    },
    chartOptions: CONFIG.chartOptions,
    chartTypeSelected: CONFIG.chartDataType.REVENUE,
    chartFormatter: CONFIG.chartLabelFormatter,
    marketkpisData: {},
    dealsKpisData: {},
    dealsOttKpisData: {},
    marketsKpisData: {},
    skinKpisData: {},
    defaultDate: CONFIG.defaultDate,
    dateList: CONFIG.dateList,
    sectionStatus: SECTION_STATUS.OK,
    availablePageSizes: [25, 50, 100, 200],
    isLoadingMarketKpis: true,
    isLoadingDealsKpis: true,
    isLoadingDealsOttKpis: true,
    isLoadingMarketsKpis: true,
    isLoadingSkinKpis: true,
    datepickerKey: 0,
    // table
    marketTotals: {},
    dealTotals: {},
    dealOttTotals: {},
    marketsTotals: {},
    skinTotals: {},
    reportOptions: [
      { id: 'CSV', name: 'CSV', value: 'CSV' },
      { id: 'XLSX', name: 'XLSX', value: 'XLSX' },
    ],
    reportSelected: '',
    isLoadingMarketTable: false,
    isLoadingDealTable: true,
    isLoadingDealOttTable: true,
    isLoadingMarketsTable: true,
    isLoadingSkinTable: true,
    marketItems: [],
    dealItems: [],
    breakdown: new Breakdown(BREAKDOWN_VALUES.date),
    totalsColumn: CONFIG.totalsColumn,
    cacheDetails: new Map(),
    detailPagination: new DetailPagination(30),
    currencySelected: 0,
    currencyOptions: CONFIG.currencyOptions,
    isLoadingMarketTotals: true,
    isLoadingDealTotals: true,
    isLoadingDealOttTotals: true,
    isLoadingMarketsTotals: true,
    isLoadingSkinTotals: true,
    dealTotalPages: 1,
    dealTotalItems: 0,
    allPlatforms: [],
    breakdownOptions: CONFIG.breakdown,
    sort: null,
    subBreakdownMixNames: CONFIG.subBreakdownMixNames,
    columnTypes: CONFIG.columnTypes,
    rowModelType: 'serverSide',
    headers: CONFIG.headers,
    breakdownHeaders: CONFIG.breakdown_headers,
    marketGridApi: null,
    marketColumnApi: null,
    marketExportParams: CONFIG.marketExportParams,
    dealExportParams: CONFIG.dealExportParams,
    dealOttExportParams: CONFIG.dealOttExportParams,
    marketsExportParams: CONFIG.marketsExportParams,
    skinExportParams: CONFIG.skinExportParams,
    dealGridApi: null,
    dealColumnApi: null,
    hasDealFilters: false,
    hasMarketFilters: false,
    hasDealOttFilters: false,
    hasMarketsFilters: false,
    hasSkinFilters: false,
    dealOttGridApi: null,
    dealOttColumnApi: null,
    marketsGridApi: null,
    marketsColumnApi: null,
    skinGridApi: null,
    skinColumnApi: null,
  }),
  computed: {
    BREAKDOWN_COLUMNS: () => BREAKDOWN_COLUMNS,
    BREAKDOWN_VALUES: () => BREAKDOWN_VALUES,
    COLUMNS: () => COLUMNS,
    COLUMNS_TO_FORMAT: () => CONFIG.columnsToFormatBy,
    notAvailable: () => 'N/A',

    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
      getStoredBreakdown: BREAKDOWN,
      reportToast: ANALYTICS_REPORT_TOAST,
      user: USER,
    }),
    isLoadingSection() {
      return (
        this.isLoadingMarketKpis ||
        this.marketChartLoading ||
        this.isLoadingMarketTable ||
        this.isLoadingMarketTotals ||
        this.isLoadingDealsKpis ||
        this.dealChartLoading ||
        this.isLoadingDealTable ||
        this.isLoadingDealTotals ||
        this.isLoadingDealsOttKpis ||
        this.dealChartOttLoading ||
        this.isLoadingDealOttTable ||
        this.isLoadingDealOttTotals ||
        this.isLoadingMarketsKpis ||
        this.marketsChartLoading ||
        this.isLoadingMarketsTable ||
        this.isLoadingMarketsTotals ||
        this.isLoadingSkinKpis ||
        this.skinChartLoading ||
        this.isLoadingSkinTable ||
        this.isLoadingSkinTotals
      );
    },
    hasDetails() {
      return !!this.breakdown.secondary;
    },
    autoGroupColumnDef() {
      if (!this.hasDetails) return null;
      let breakdownColumn;
      if (this.breakdown.secondary === BREAKDOWN_VALUES.deal) {
        breakdownColumn = this.breakdownHeaders.find(col => col.title === BREAKDOWN_COLUMNS.NAME.title);
      } else if (this.breakdown.secondary === BREAKDOWN_VALUES.date) {
        breakdownColumn = this.breakdownHeaders.find(col => col.title === BREAKDOWN_COLUMNS.DATE.title);
      } else if (this.breakdown.secondary === BREAKDOWN_VALUES.country) {
        breakdownColumn = this.breakdownHeaders.find(col => col.title === BREAKDOWN_COLUMNS.COUNTRY.title);
      } else if (this.breakdown.secondary === BREAKDOWN_VALUES.platform) {
        breakdownColumn = this.breakdownHeaders.find(col => col.title === BREAKDOWN_COLUMNS.PLATFORM.title);
      }
      return breakdownColumn;
    },
    sortByDefault() {
      let returnSort;
      switch (this.breakdown.primary) {
        case BREAKDOWN_VALUES.deal:
          returnSort = { field: BREAKDOWN_COLUMNS.NAME.value, order: 'ASC' };
          break;
        case BREAKDOWN_VALUES.country:
          returnSort = { field: BREAKDOWN_COLUMNS.COUNTRY.value, order: 'ASC' };
          break;
        case BREAKDOWN_VALUES.platform:
          returnSort = { field: BREAKDOWN_COLUMNS.PLATFORM.value, order: 'ASC' };
          break;
        default:
          returnSort = { field: BREAKDOWN_COLUMNS.DATE.value, order: 'DESC' };
          break;
      }
      return returnSort;
    },
    subBreakdownCombo() {
      return `${this.breakdown.primary}_${this.breakdown.secondary}`;
    },
    storedBreakdown() {
      return this.getStoredBreakdown(this.$route.name);
    },
    isLoadingTables() {
      return (
        this.isLoadingDealTable ||
        this.isLoadingMarketTable ||
        this.isLoadingDealOttTable ||
        this.isLoadingMarketsTable ||
        this.isLoadingSkinTable
      );
    },
    breakdownDisabled() {
      return this.isLoadingTables;
    },
    totalsColumnNames() {
      return CONFIG.totalsColumn.map(col => col.name);
    },
    reportToastLoading() {
      return !!this.reportToast?.id;
    },
    quickFiltersDate() {
      const rangeStart = this.filterQuick.range_start;
      const rangeEnd = this.filterQuick.range_end;

      const startDate = rangeStart ? stringToDate(rangeStart) : this.defaultDate.startDate;
      const endDate = rangeEnd ? stringToDate(rangeEnd) : this.defaultDate.endDate;
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 0);

      return { startDate, endDate };
    },
    contextUserRole() {
      return this.user?.contextRoles.find(contextRole => contextRole.context === CONTEXTS.DASHBOARD_SSP.id).role;
    },
    // columnsForCustomColumns() {
    //   return this.headers
    //     .filter(header => !header.rolesAllowed.length || header.rolesAllowed.includes(this.contextUserRole))
    //     .filter(header => !header.breakdowns.length || header.breakdowns.includes(this.breakdown.primary));
    // },
    availableColumnsByBreakdown() {
      return this.breakdownHeaders
        .concat(this.headers)
        .filter(header => !header.rolesAllowed.length || header.rolesAllowed.includes(this.contextUserRole))
        .filter(header => !header.breakdowns.length || header.breakdowns.includes(this.breakdown.primary));
    },
    tableColumnsDefinition() {
      return [...this.availableColumnsByBreakdown].map(
        ({
          headerName,
          field,
          cellClassRules,
          visible,
          maxWidth,
          minWidth,
          width,
          filter,
          filterParams,
          type,
          pinned,
          sort,
          suppressSizeToFit,
          suppressAutoSize,
          comparator,
        }) => ({
          headerName,
          field,
          hide: this.isGroupColumn(field) || !visible,
          cellClassRules,
          maxWidth,
          minWidth,
          width,
          pinned,
          filter,
          filterParams,
          type,
          sort,
          suppressSizeToFit,
          suppressAutoSize,
          rowGroup: this.isGroupColumn(field),
          comparator,
        })
      );
    },
    chartRevenue() {
      return this.chartTypeSelected === CONFIG.chartDataType.REVENUE;
    },
    chartByPlatform() {
      return this.chartTypeSelected === CONFIG.chartDataType.BYPLATFORM;
    },
    marketChartSeries() {
      const chartSeries = [];
      if (this.chartTypeSelected === CONFIG.chartDataType.BYPLATFORM) {
        const currency = this.chartRevenue && this.currencySelected === 1 ? 'Usd' : '';
        this.marketChartData[CONFIG.chartDataType.BYPLATFORM].forEach((chartInfoMap, platformName) => {
          const chartSerie = {
            name: platformName,
            type: 'line',
            data: chartInfoMap?.[`netRevenue${currency}`] || new Map(),
          };
          chartSeries.push(chartSerie);
        });

        if (chartSeries.length === 0) return chartSeries;

        const chartTypeSelected = this.chartTypeSelected;
        chartSeries[0].xaxisConfigExtend = { labels: { formatter: this.chartFormatter } };
        chartSeries[0].yaxisConfigExtend = {
          labels: {
            formatter(val) {
              switch (chartTypeSelected) {
                case CONFIG.chartDataType.BYPLATFORM:
                  if (!val) return '0,00';
                  return `${thousandSeparator(
                    val.toLocaleString('es-ES', {
                      style: 'decimal',
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })
                  )}€`;
                default:
                  if (!val) return '0';
                  return thousandSeparator(val.toLocaleString('es-ES'));
              }
            },
          },
        };
      } else {
        chartSeries.push({
          name: 'Net revenue',
          type: 'line',
          data: this.marketChartData[CONFIG.chartDataType.REVENUE],
        });
        if (chartSeries.length === 0) return chartSeries;

        chartSeries[0].yaxisConfigExtend = {
          labels: {
            formatter(val) {
              if (!val) return '0,00';
              return `${thousandSeparator(
                val.toLocaleString('es-ES', {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              )}€`;
            },
          },
        };
      }

      return chartSeries;
    },
    dealChartSeries() {
      const chartSeries = [];
      if (this.chartTypeSelected === CONFIG.chartDataType.BYPLATFORM) {
        const currency = this.currencySelected === 0 ? '' : 'Usd';
        this.dealChartData[CONFIG.chartDataType.BYPLATFORM].forEach((chartInfoMap, platformName) => {
          const chartSerie = {
            name: platformName,
            type: 'line',
            data: chartInfoMap?.[`netRevenue${currency}`] || new Map(),
          };
          chartSeries.push(chartSerie);
        });

        if (chartSeries.length === 0) return chartSeries;

        const chartTypeSelected = this.chartTypeSelected;
        chartSeries[0].xaxisConfigExtend = { labels: { formatter: this.chartFormatter } };
        chartSeries[0].yaxisConfigExtend = {
          labels: {
            formatter(val) {
              switch (chartTypeSelected) {
                case CONFIG.chartDataType.BYPLATFORM:
                  if (!val) return '0,00';
                  return `${thousandSeparator(
                    val.toLocaleString('es-ES', {
                      style: 'decimal',
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })
                  )}€`;
                default:
                  if (!val) return '0';
                  return thousandSeparator(val.toLocaleString('es-ES'));
              }
            },
          },
        };
      } else {
        chartSeries.push({
          name: 'Net revenue',
          type: 'line',
          data: this.dealChartData[CONFIG.chartDataType.REVENUE],
        });
        if (chartSeries.length === 0) return chartSeries;

        chartSeries[0].yaxisConfigExtend = {
          labels: {
            formatter(val) {
              if (!val) return '0,00';
              return `${thousandSeparator(
                val.toLocaleString('es-ES', {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              )}€`;
            },
          },
        };
      }
      return chartSeries;
    },
    dealOttChartSeries() {
      const chartSeries = [];
      if (this.chartTypeSelected === CONFIG.chartDataType.BYPLATFORM) {
        const currency = this.currencySelected === 0 ? '' : 'Usd';
        this.dealOttChartData[CONFIG.chartDataType.BYPLATFORM].forEach((chartInfoMap, platformName) => {
          const chartSerie = {
            name: platformName,
            type: 'line',
            data: chartInfoMap?.[`netRevenue${currency}`] || new Map(),
          };
          chartSeries.push(chartSerie);
        });

        if (chartSeries.length === 0) return chartSeries;

        const chartTypeSelected = this.chartTypeSelected;
        chartSeries[0].xaxisConfigExtend = { labels: { formatter: this.chartFormatter } };
        chartSeries[0].yaxisConfigExtend = {
          labels: {
            formatter(val) {
              switch (chartTypeSelected) {
                case CONFIG.chartDataType.BYPLATFORM:
                  if (!val) return '0,00';
                  return `${thousandSeparator(
                    val.toLocaleString('es-ES', {
                      style: 'decimal',
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })
                  )}€`;
                default:
                  if (!val) return '0';
                  return thousandSeparator(val.toLocaleString('es-ES'));
              }
            },
          },
        };
      } else {
        chartSeries.push({
          name: 'Net revenue',
          type: 'line',
          data: this.dealOttChartData[CONFIG.chartDataType.REVENUE],
        });
        if (chartSeries.length === 0) return chartSeries;

        chartSeries[0].yaxisConfigExtend = {
          labels: {
            formatter(val) {
              if (!val) return '0,00';
              return `${thousandSeparator(
                val.toLocaleString('es-ES', {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              )}€`;
            },
          },
        };
      }
      return chartSeries;
    },
    marketsChartSeries() {
      const chartSeries = [];
      if (this.chartTypeSelected === CONFIG.chartDataType.BYPLATFORM) {
        const currency = this.currencySelected === 0 ? '' : 'Usd';
        this.marketsChartData[CONFIG.chartDataType.BYPLATFORM].forEach((chartInfoMap, platformName) => {
          const chartSerie = {
            name: platformName,
            type: 'line',
            data: chartInfoMap?.[`netRevenue${currency}`] || new Map(),
          };
          chartSeries.push(chartSerie);
        });

        if (chartSeries.length === 0) return chartSeries;

        const chartTypeSelected = this.chartTypeSelected;
        chartSeries[0].xaxisConfigExtend = { labels: { formatter: this.chartFormatter } };
        chartSeries[0].yaxisConfigExtend = {
          labels: {
            formatter(val) {
              switch (chartTypeSelected) {
                case CONFIG.chartDataType.BYPLATFORM:
                  if (!val) return '0,00';
                  return `${thousandSeparator(
                    val.toLocaleString('es-ES', {
                      style: 'decimal',
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })
                  )}€`;
                default:
                  if (!val) return '0';
                  return thousandSeparator(val.toLocaleString('es-ES'));
              }
            },
          },
        };
      } else {
        chartSeries.push({
          name: 'Net revenue',
          type: 'line',
          data: this.marketsChartData[CONFIG.chartDataType.REVENUE],
        });
        if (chartSeries.length === 0) return chartSeries;

        chartSeries[0].yaxisConfigExtend = {
          labels: {
            formatter(val) {
              if (!val) return '0,00';
              return `${thousandSeparator(
                val.toLocaleString('es-ES', {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              )}€`;
            },
          },
        };
      }
      return chartSeries;
    },
    skinChartSeries() {
      const chartSeries = [];
      if (this.chartTypeSelected === CONFIG.chartDataType.BYPLATFORM) {
        const currency = this.currencySelected === 0 ? '' : 'Usd';
        this.skinChartData[CONFIG.chartDataType.BYPLATFORM].forEach((chartInfoMap, platformName) => {
          const chartSerie = {
            name: platformName,
            type: 'line',
            data: chartInfoMap?.[`netRevenue${currency}`] || new Map(),
          };
          chartSeries.push(chartSerie);
        });

        if (chartSeries.length === 0) return chartSeries;

        const chartTypeSelected = this.chartTypeSelected;
        chartSeries[0].xaxisConfigExtend = { labels: { formatter: this.chartFormatter } };
        chartSeries[0].yaxisConfigExtend = {
          labels: {
            formatter(val) {
              switch (chartTypeSelected) {
                case CONFIG.chartDataType.BYPLATFORM:
                  if (!val) return '0,00';
                  return `${thousandSeparator(
                    val.toLocaleString('es-ES', {
                      style: 'decimal',
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })
                  )}€`;
                default:
                  if (!val) return '0';
                  return thousandSeparator(val.toLocaleString('es-ES'));
              }
            },
          },
        };
      } else {
        chartSeries.push({
          name: 'Net revenue',
          type: 'line',
          data: this.skinChartData[CONFIG.chartDataType.REVENUE],
        });
        if (chartSeries.length === 0) return chartSeries;

        chartSeries[0].yaxisConfigExtend = {
          labels: {
            formatter(val) {
              if (!val) return '0,00';
              return `${thousandSeparator(
                val.toLocaleString('es-ES', {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              )}€`;
            },
          },
        };
      }
      return chartSeries;
    },
    limitDateRange() {
      const { startDate } = this.quickFiltersDate;
      const max = addMonths(startDate, 6);
      return {
        max: max < CONFIG.limitMaxDate ? max : CONFIG.limitMaxDate,
      };
    },
    defaultColDef() {
      const isSecondLevelApplied = !!this.breakdown.secondary;
      return { ...CONFIG.defaultColDef, floatingFilter: !isSecondLevelApplied };
    },
  },
  watch: {
    breakdown: 'handlerBreakdown',
  },
  created() {
    window.addEventListener('resize', this.resizeColumns);
    this.setBreakdown();
    this.setDefaultDateFilter();
  },
  async mounted() {
    await this.filtersLoadAllfiltersOnMounted();
    this.datepickerKey++;
    this.sort = this.sortByDefault;
    await Promise.all([
      this.getAnalyticsMarketKpis(),
      this.getMarketTotals(),
      this.getAnalyticsDealKpis(),
      this.getAnalyticsDealOttKpis(),
      this.getDealTotals(),
      this.getDealOttTotals(),
      this.getAnalyticsMarketsKpis(),
      this.getMarketsTotals(),
      this.getAnalyticsSkinKpis(),
      this.getSkinTotals(),
    ]);
    await this.getAllPlatforms();
    await Promise.all([
      this.marketChartInit(),
      this.dealChartInit(),
      this.dealChartOttInit(),
      this.marketsChartInit(),
      this.skinChartInit(),
    ]);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeColumns);
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
      saveBreakdown: SAVE_BREAKDOWN,
      showReportToast: SET_ANALYTICS_REPORT_TOAST,
    }),
    getIngestionJobNotifications,
    setSortDefaultOnTable() {
      this.breakdownHeaders.forEach(header => {
        if (header.value === this.sortByDefault.field) {
          const defaultSort = {
            state: [{ colId: header.field, sort: this.sortByDefault.order.toLowerCase() }],
            defaultState: { sort: null },
          };
          this.marketColumnApi.applyColumnState(defaultSort);
          this.dealColumnApi.applyColumnState(defaultSort);
          this.dealOttColumnApi.applyColumnState(defaultSort);
          this.marketsColumnApi.applyColumnState(defaultSort);
        }
      });
    },
    onChartTypeChange(event) {
      this.chartTypeSelected = event.id;
      this.marketChartInit();
      this.dealChartInit();
      this.dealChartOttInit();
      this.marketsChartInit();
      this.skinChartInit();
    },
    onMarketGridReady({ api, columnApi }) {
      this.marketGridApi = api;
      this.marketColumnApi = columnApi;

      this.marketGridApi.setServerSideDatasource({
        getRows: async params => {
          this.marketGridApi.hideOverlay();
          this.setSortDefaultOnTable();
          this.isLoadingMarketTable = true;
          this.marketGridApi.showLoadingOverlay();
          const rowData = params.parentNode.data;
          let data;
          try {
            if (!params.request.groupKeys.length) {
              data = await this.getFirstLevelItems(CONFIG.platformType.OPEN_MARKET);
            } else {
              let itemId;
              switch (this.breakdown.primary) {
                case BREAKDOWN_VALUES.deal:
                  itemId = rowData.externalDealId;
                  break;
                case BREAKDOWN_VALUES.platform:
                  itemId = rowData.platformId;
                  break;
                default:
                  itemId = rowData.breakdown;
                  break;
              }
              data = await this.getSecondLevelItems(itemId, CONFIG.platformType.OPEN_MARKET);
            }
            params.success({
              rowData: data,
            });
          } catch {
            params.fail();
          } finally {
            if (this.marketGridApi.getDisplayedRowCount() === 0) this.marketGridApi.showNoRowsOverlay();
            else {
              this.marketGridApi.hideOverlay();
            }
            this.isLoadingMarketTable = false;
            this.marketGridApi.sizeColumnsToFit();
          }
        },
      });
    },
    onDealGridReady({ api, columnApi }) {
      this.dealGridApi = api;
      this.dealColumnApi = columnApi;
      this.isLoadingDealTable = true;
      this.dealGridApi.setServerSideDatasource({
        getRows: async params => {
          this.dealGridApi.hideOverlay();
          this.setSortDefaultOnTable();
          this.dealGridApi.showLoadingOverlay();
          const rowData = params.parentNode.data;
          let data;
          try {
            if (!params.request.groupKeys.length) {
              data = await this.getFirstLevelItems(CONFIG.platformType.DEAL);
            } else {
              let itemId;
              switch (this.breakdown.primary) {
                case BREAKDOWN_VALUES.deal:
                  itemId = rowData.dealId;
                  break;
                case BREAKDOWN_VALUES.platform:
                  itemId = rowData.platformId;
                  break;
                default:
                  itemId = rowData.breakdown;
                  break;
              }
              data = await this.getSecondLevelItems(itemId, CONFIG.platformType.DEAL);
            }
            params.success({
              rowData: data,
            });
          } catch {
            params.fail();
          } finally {
            if (this.dealGridApi.getDisplayedRowCount() === 0) this.dealGridApi.showNoRowsOverlay();
            else {
              this.dealGridApi.hideOverlay();
            }
            this.isLoadingDealTable = false;
            this.dealGridApi.sizeColumnsToFit();
          }
        },
      });
    },
    onDealOttGridReady({ api, columnApi }) {
      this.dealOttGridApi = api;
      this.dealOttColumnApi = columnApi;
      this.isLoadingDealOttTable = true;
      this.dealOttGridApi.setServerSideDatasource({
        getRows: async params => {
          this.dealOttGridApi.hideOverlay();
          this.setSortDefaultOnTable();
          this.dealOttGridApi.showLoadingOverlay();
          const rowData = params.parentNode.data;
          let data;
          try {
            if (!params.request.groupKeys.length) {
              data = await this.getFirstLevelItems(CONFIG.platformType.OTT);
            } else {
              let itemId;
              switch (this.breakdown.primary) {
                case BREAKDOWN_VALUES.deal:
                  itemId = rowData.dealId;
                  break;
                case BREAKDOWN_VALUES.platform:
                  itemId = rowData.platformId;
                  break;
                default:
                  itemId = rowData.breakdown;
                  break;
              }
              data = await this.getSecondLevelItems(itemId, CONFIG.platformType.OTT);
            }
            params.success({
              rowData: data,
            });
          } catch {
            params.fail();
          } finally {
            if (this.dealOttGridApi.getDisplayedRowCount() === 0) this.dealOttGridApi.showNoRowsOverlay();
            else {
              this.dealOttGridApi.hideOverlay();
            }
            this.isLoadingDealOttTable = false;
            this.dealOttGridApi.sizeColumnsToFit();
          }
        },
      });
    },
    onMarketsGridReady({ api, columnApi }) {
      this.marketsGridApi = api;
      this.marketsColumnApi = columnApi;
      this.isLoadingMarketsTable = true;
      this.marketsGridApi.setServerSideDatasource({
        getRows: async params => {
          this.marketsGridApi.hideOverlay();
          this.setSortDefaultOnTable();
          this.marketsGridApi.showLoadingOverlay();
          const rowData = params.parentNode.data;
          let data;
          try {
            if (!params.request.groupKeys.length) {
              data = await this.getFirstLevelItems(CONFIG.platformType.MARKET);
            } else {
              let itemId;
              switch (this.breakdown.primary) {
                case BREAKDOWN_VALUES.deal:
                  itemId = rowData.dealId;
                  break;
                case BREAKDOWN_VALUES.platform:
                  itemId = rowData.platformId;
                  break;
                default:
                  itemId = rowData.breakdown;
                  break;
              }
              data = await this.getSecondLevelItems(itemId, CONFIG.platformType.MARKET);
            }
            params.success({
              rowData: data,
            });
          } catch {
            params.fail();
          } finally {
            if (this.marketsGridApi.getDisplayedRowCount() === 0) this.marketsGridApi.showNoRowsOverlay();
            else {
              this.marketsGridApi.hideOverlay();
            }
            this.isLoadingMarketsTable = false;
            this.marketsGridApi.sizeColumnsToFit();
          }
        },
      });
    },
    onSkinGridReady({ api, columnApi }) {
      this.skinGridApi = api;
      this.skinColumnApi = columnApi;
      this.isLoadingSkinTable = true;
      this.skinGridApi.setServerSideDatasource({
        getRows: async params => {
          this.skinGridApi.hideOverlay();
          this.setSortDefaultOnTable();
          this.skinGridApi.showLoadingOverlay();
          const rowData = params.parentNode.data;
          let data;
          try {
            if (!params.request.groupKeys.length) {
              data = await this.getFirstLevelItems(CONFIG.platformType.SKIN);
            } else {
              let itemId;
              switch (this.breakdown.primary) {
                case BREAKDOWN_VALUES.deal:
                  itemId = rowData.dealId;
                  break;
                case BREAKDOWN_VALUES.platform:
                  itemId = rowData.platformId;
                  break;
                default:
                  itemId = rowData.breakdown;
                  break;
              }
              data = await this.getSecondLevelItems(itemId, CONFIG.platformType.SKIN);
            }
            params.success({
              rowData: data,
            });
          } catch {
            params.fail();
          } finally {
            if (this.skinGridApi.getDisplayedRowCount() === 0) this.skinGridApi.showNoRowsOverlay();
            else {
              this.skinGridApi.hideOverlay();
            }
            this.isLoadingSkinTable = false;
            this.skinGridApi.sizeColumnsToFit();
          }
        },
      });
    },
    async getFirstLevelItems(platformType) {
      const auxParams = this.setParamsToTable();
      auxParams.page = this.currentPage;
      auxParams.itemsPerPage = 2000;
      auxParams.addFilter('breakdown', this.breakdown.primary);
      auxParams.addFilter('analyticsDeal.type', platformType);
      auxParams.addSort(this.sortByDefault.field, this.sortByDefault.order);

      const { data } = await getTableV2(this.activeClient.id, auxParams);
      return data;
    },
    // es necesario este metodo? no se hacen agrupaciones en esta vista
    async getSecondLevelItems(itemId, platformType) {
      const params = this.setParamsToTable();
      params.itemsPerPage = 2000;
      params.addFilter('analyticsDeal.type', platformType);
      params.addFilter('breakdown', this.breakdown.primary);
      params.addFilter('subBreakdown', this.breakdown.secondary);
      switch (this.breakdown.primary) {
        case BREAKDOWN_VALUES.date:
          params.addFilter('range_start', new Date(itemId).setHours(0, 0, 0, 0));
          params.addFilter('range_end', new Date(itemId).setHours(23, 59, 59, 0));
          break;
        case BREAKDOWN_VALUES.country:
          params.addFilter('analyticsDeal.country', itemId);
          break;
        case BREAKDOWN_VALUES.platform:
          params.addFilter('platform.id', itemId);
          break;
        case BREAKDOWN_VALUES.deal:
          params.addFilter('analyticsDeal.externalDealId', itemId);
          break;
      }
      switch (this.breakdown.secondary) {
        case BREAKDOWN_VALUES.date:
          params.addSort(BREAKDOWN_COLUMNS.DATE.value, 'DESC');
          break;
        case BREAKDOWN_VALUES.country:
          params.addSort(BREAKDOWN_COLUMNS.COUNTRY.value, 'ASC');
          break;
        case BREAKDOWN_VALUES.platform:
          params.addSort(BREAKDOWN_COLUMNS.PLATFORM.value, 'ASC');
          break;
        case BREAKDOWN_VALUES.deal:
          params.addSort(BREAKDOWN_COLUMNS.NAME.value, 'ASC');
          break;
      }
      try {
        const { data } = await getTableV2(this.activeClient.id, params);
        return data;
      } catch {
        this.sectionStatus = SECTION_STATUS.ERROR;
      }
    },
    formatColValue(colValue) {
      return colValue.slice(14);
    },
    resetBreakdown() {
      this.breakdown = new Breakdown(BREAKDOWN_VALUES.date);
    },
    setBreakdown() {
      const retrievedPrimary = this.queryParams.breakdown || this.storedBreakdown?.primary || BREAKDOWN_VALUES.date;
      const retrievedSecondary = this.queryParams.subBreakdown || this.storedBreakdown?.secondary;
      this.breakdown.primary = retrievedPrimary;
      this.breakdown.secondary = retrievedSecondary;
    },
    async handlerBreakdown({ primary, secondary }) {
      this.addQueryParams({ breakdown: primary });
      if (secondary) {
        this.addQueryParams({ subBreakdown: secondary });
      } else {
        this.removeQueryParam('subBreakdown');
      }

      this.saveBreakdown({ idView: this.$route.name, breakdown: { primary, secondary } });

      this.sort = this.sortByDefault;

      await this.queryParamsRouterReplace();
      this.setSortDefaultOnTable();

      this.marketColumnApi.resetColumnGroupState();

      if (this.marketGridApi) this.marketGridApi?.refreshServerSideStore();
      if (this.dealGridApi) this.dealGridApi?.refreshServerSideStore();
    },
    async getAllPlatforms() {
      try {
        const { data } = await getAllConnectedPlatforms();
        this.allPlatforms = data;
      } catch {
        this[CREATE_TOAST](Toast.error('Platforms', 'We can not load Platforms'));
      }
    },

    async getAnalyticsKpis(loadingVar, platformType, service, kpisVar, headMsg) {
      this[loadingVar] = true;
      const { isCancel } = await ApiRequest(async () => {
        const auxParams = this.setParamsToTable();
        auxParams.addFilter('analyticsDeal.type', platformType);
        this[kpisVar] = await service(this.activeClient.id, auxParams);
      }).catch(() => {
        this.sectionStatus = SECTION_STATUS.ERROR;
        this[CREATE_TOAST](Toast.error(headMsg, 'We can not get KPIS.'));
      });
      if (!isCancel) this[loadingVar] = false;
    },
    async getAnalyticsMarketKpis() {
      await this.getAnalyticsKpis(
        'isLoadingMarketKpis',
        CONFIG.platformType.OPEN_MARKET,
        getAnalyticsMarketKpis,
        'marketkpisData',
        'Open Market'
      );
    },
    async getAnalyticsDealKpis() {
      await this.getAnalyticsKpis(
        'isLoadingDealsKpis',
        CONFIG.platformType.DEAL,
        getAnalyticsDealKpis,
        'dealsKpisData',
        'Deals'
      );
    },
    async getAnalyticsDealOttKpis() {
      await this.getAnalyticsKpis(
        'isLoadingDealsOttKpis',
        CONFIG.platformType.OTT,
        getAnalyticsDealOttKpis,
        'dealsOttKpisData',
        'Deal OTT'
      );
    },
    async getAnalyticsMarketsKpis() {
      await this.getAnalyticsKpis(
        'isLoadingMarketsKpis',
        CONFIG.platformType.MARKET,
        getAnalyticsMarketsKpis,
        'marketsKpisData',
        'Markets'
      );
    },
    async getAnalyticsSkinKpis() {
      await this.getAnalyticsKpis(
        'isLoadingSkinKpis',
        CONFIG.platformType.SKIN,
        getAnalyticsSkinKpis,
        'skinKpisData',
        'Skin'
      );
    },
    async chartInit(loadingVar, service, platformType, msgHeader) {
      if (this.chartByPlatform && !this.marketChartData[CONFIG.chartDataType.BYPLATFORM].size) {
        this[loadingVar] = true;
        const { isCancel } = await ApiRequest(async () => {
          this.sectionStatus = SECTION_STATUS.OK;
          const auxParams = this.setChartParams(platformType);
          auxParams.addFilter('subBreakdown', 'PlatformId');
          const { data } = await service(this.activeClient.id, auxParams);
          this.formatDataToSeriesChart(data, platformType);
        }).catch(() => {
          this.sectionStatus = SECTION_STATUS.ERROR;
          this[CREATE_TOAST](Toast.error(msgHeader, 'We can not get chart data.'));
        });
        if (!isCancel) this[loadingVar] = false;
      } else if (this.chartRevenue && !this.marketChartData[CONFIG.chartDataType.REVENUE].size) {
        this[loadingVar] = true;
        const { isCancel } = await ApiRequest(async () => {
          this.sectionStatus = SECTION_STATUS.OK;
          const auxParams = this.setChartParams(platformType);
          auxParams.addQ('netRevenue');
          const { data } = await service(this.activeClient.id, auxParams);
          this.formatNetRevenueSeries(data, platformType);
        }).catch(() => {
          this.sectionStatus = SECTION_STATUS.ERROR;
          this[CREATE_TOAST](Toast.error(msgHeader, 'We can not get chart data.'));
        });
        if (!isCancel) this[loadingVar] = false;
        this[loadingVar] = false;
      }
    },
    async marketChartInit() {
      await this.chartInit('marketChartLoading', getMarketChartV2, CONFIG.platformType.OPEN_MARKET, 'Open Market');
    },
    async dealChartInit() {
      await this.chartInit('dealChartLoading', getDealChartV2, CONFIG.platformType.DEAL, 'Deals');
    },
    async dealChartOttInit() {
      await this.chartInit('dealChartOttLoading', getDealOttChartV2, CONFIG.platformType.OTT, 'Deals OTT');
    },
    async marketsChartInit() {
      await this.chartInit('marketsChartLoading', getAnalyticsMarketsChart, CONFIG.platformType.MARKET, 'Markets');
    },
    async skinChartInit() {
      await this.chartInit('skinChartLoading', getAnalyticsSkinChart, CONFIG.platformType.SKIN, 'Skin');
    },
    setChartParams(type) {
      const auxParams = this.setParamsToTable();
      auxParams.addFilter('analyticsDeal.type', type);
      auxParams.addFilter('breakdown', BREAKDOWN_VALUES.date);
      auxParams.addSort(BREAKDOWN_COLUMNS.DATE.value, 'DESC');
      auxParams.itemsPerPage = 0;
      return auxParams;
    },
    formatNetRevenueSeries(rawData, type) {
      if (!rawData) return [];
      const chartPoints = new Map();
      try {
        rawData.forEach(pointByPlatform => {
          const date = pointByPlatform.breakdown;
          if (!chartPoints.has(date)) {
            chartPoints.set(date, parseFloat(pointByPlatform.netRevenue));
          } else {
            const newPoint = chartPoints.get(date) + parseFloat(pointByPlatform.netRevenue);
            chartPoints.set(date, newPoint);
          }
        });
        switch (type) {
          case CONFIG.platformType.DEAL:
            this.dealChartData[CONFIG.chartDataType.REVENUE] = chartPoints;
            break;
          case CONFIG.platformType.OPEN_MARKET:
            this.marketChartData[CONFIG.chartDataType.REVENUE] = chartPoints;
            break;
          case CONFIG.platformType.OTT:
            this.dealOttChartData[CONFIG.chartDataType.REVENUE] = chartPoints;
            break;
          case CONFIG.platformType.MARKET:
            this.marketsChartData[CONFIG.chartDataType.REVENUE] = chartPoints;
            break;
          case CONFIG.platformType.SKIN:
            this.skinChartData[CONFIG.chartDataType.REVENUE] = chartPoints;
            break;
        }
      } catch {
        this.createToast(Toast.error(`${type} Chart`, 'We can not parse graph data.'));
      }
    },
    formatDataToSeriesChart(rawData, type) {
      if (!rawData) return [];
      try {
        const { REVENUE, REQUEST, IMPRESSION, REVENUEUSD } = CONFIG.chartDataType;
        const platformMap = new Map();

        this.allPlatforms.forEach(platform => {
          const dataByPlatform = rawData.filter(raw => raw.platformName === platform.name);
          if (!dataByPlatform.length) {
            platformMap.set(platform.name, {
              [IMPRESSION]: new Map(),
              [REQUEST]: new Map(),
              [REVENUE]: new Map(),
              [REVENUEUSD]: new Map(),
            });
          }
          dataByPlatform.forEach(chartPoint => {
            const chartData = platformMap.has(platform.name)
              ? platformMap.get(platform.name)
              : {
                  [IMPRESSION]: new Map(),
                  [REQUEST]: new Map(),
                  [REVENUE]: new Map(),
                  [REVENUEUSD]: new Map(),
                };
            const key = chartPoint.breakdown;
            chartData[IMPRESSION].set(key, parseFloat(chartPoint.impressions)?.toFixed(0) || 0);
            chartData[REQUEST].set(key, parseFloat(chartPoint.requests)?.toFixed(0) || 0);
            chartData[REVENUE].set(key, parseFloat(chartPoint.netRevenue)?.toFixed(3) || 0);
            chartData[REVENUEUSD].set(key, parseFloat(chartPoint.netRevenueUsd)?.toFixed(3) || 0);

            platformMap.set(platform.name, chartData);
          });
        });

        switch (type) {
          case CONFIG.platformType.OPEN_MARKET:
            this.marketChartData[CONFIG.chartDataType.BYPLATFORM] = platformMap;
            break;
          case CONFIG.platformType.DEAL:
            this.dealChartData[CONFIG.chartDataType.BYPLATFORM] = platformMap;
            break;
          case CONFIG.platformType.OTT:
            this.dealOttChartData[CONFIG.chartDataType.BYPLATFORM] = platformMap;
            break;
          case CONFIG.platformType.MARKET:
            this.marketsChartData[CONFIG.chartDataType.BYPLATFORM] = platformMap;
            break;
          case CONFIG.platformType.SKIN:
            this.skinChartData[CONFIG.chartDataType.BYPLATFORM] = platformMap;
            break;
        }
      } catch {
        this.createToast(Toast.error(`${type} Chart`, 'We can not parse graph data.'));
      }
    },

    setFilterQuick({ startDate, endDate }) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 0);
      this.filterQuick.range_start = startDate.toISOString();
      this.filterQuick.range_end = endDate.toISOString();

      // TODO Is this necessary?
      this.datepickerKey++;
    },
    setDefaultDateFilter() {
      this.setFilterQuick(this.quickFiltersDate);
    },
    async updateFilters(filters = []) {
      this.replaceQueryParams({
        page: 1,
        breakdown: this.queryParams.breakdown,
        subBreakdown: this.queryParams.subBreakdown,
      });

      this.filterFind = filters;
      await this.filtersUpdateFiltersOnStoreAndURL();
    },
    async changeFilters(filters) {
      await this.updateFilters(filters);
      this.dealGridApi.refreshServerSideStore();
      this.marketGridApi.refreshServerSideStore();
      this.marketsGridApi.refreshServerSideStore();
      this.dealOttGridApi.refreshServerSideStore();
      await Promise.all([
        this.marketChartInit(),
        this.dealChartInit(),
        this.dealChartOttInit(),
        this.marketsChartInit(),
        this.skinChartInit(),
        this.getMarketTotals(),
        this.getDealTotals(),
        this.getDealOttTotals(),
        this.getMarketsTotals(),
        this.getSkinTotals(),
      ]);
    },
    isGroupColumn(field) {
      if (!this.hasDetails) return false;
      return (
        !!(this.breakdown.primary === BREAKDOWN_VALUES.deal && field === 'dealName') ||
        !!(this.breakdown.primary === BREAKDOWN_VALUES.date && field === 'date') ||
        !!(this.breakdown.primary === BREAKDOWN_VALUES.country && field === 'country') ||
        !!(this.breakdown.primary === BREAKDOWN_VALUES.platform && field === 'platformName')
      );
    },
    async onQuickFiltersDate(value) {
      this.setFilterQuick(value || this.defaultDate);
      this.resetChartData();
      await this.changeFilters(this.filterFind);
    },
    resetChartData() {
      this.marketChartData[CONFIG.chartDataType.REVENUE] = new Map();
      this.marketChartData[CONFIG.chartDataType.BYPLATFORM] = new Map();
      this.dealChartData[CONFIG.chartDataType.REVENUE] = new Map();
      this.dealChartData[CONFIG.chartDataType.BYPLATFORM] = new Map();
      this.dealOttChartData[CONFIG.chartDataType.REVENUE] = new Map();
      this.dealOttChartData[CONFIG.chartDataType.BYPLATFORM] = new Map();
      this.marketsChartData[CONFIG.chartDataType.REVENUE] = new Map();
      this.marketsChartData[CONFIG.chartDataType.BYPLATFORM] = new Map();
      this.skinChartData[CONFIG.chartDataType.REVENUE] = new Map();
      this.skinChartData[CONFIG.chartDataType.BYPLATFORM] = new Map();
    },
    changePage(page) {
      this.goToPage({ page });
    },
    async getTotals(loadingVar, totalsVar, service, platformType, msgHeader) {
      const { isCancel } = await ApiRequest(async () => {
        this[loadingVar] = true;
        const auxParams = this.setParamsToTable();
        auxParams.addFilter('analyticsDeal.type', platformType);
        this[totalsVar] = await service(this.activeClient.id, auxParams);
      }).catch(() => {
        this.sectionStatus = SECTION_STATUS.ERROR;
        this.createToast(Toast.error(msgHeader, 'We couldnt load totals'));
      });
      if (!isCancel) this[loadingVar] = false;
    },
    async getMarketTotals() {
      await this.getTotals(
        'isLoadingMarketTotals',
        'marketTotals',
        getAnalyticsMarketTotals,
        CONFIG.platformType.OPEN_MARKET,
        'Open Market'
      );
    },
    async getDealTotals() {
      await this.getTotals(
        'isLoadingDealTotals',
        'dealTotals',
        getAnalyticsDealTotals,
        CONFIG.platformType.DEAL,
        'Deals'
      );
    },
    async getDealOttTotals() {
      await this.getTotals(
        'isLoadingDealOttTotals',
        'dealOttTotals',
        getAnalyticsDealOttTotals,
        CONFIG.platformType.OTT,
        'Deals OTT'
      );
    },
    async getMarketsTotals() {
      await this.getTotals(
        'isLoadingMarketsTotals',
        'marketsTotals',
        getAnalyticsMarketsTotals,
        CONFIG.platformType.MARKET,
        'Markets'
      );
    },
    async getSkinTotals() {
      await this.getTotals(
        'isLoadingSkinTotals',
        'skinTotals',
        getAnalyticsSkinTotals,
        CONFIG.platformType.SKIN,
        'Skin'
      );
    },
    handlerReport(value, exportParams, fileName, api) {
      const params = deepClone(this[exportParams]);
      const date = dateToYYYYMMDD(new Date());
      if (value === 'CSV') {
        params.fileName = `${date + fileName}.csv`;
        this[api].exportDataAsCsv(params);
      } else if (value === 'XLSX') {
        params.fileName = `${date + fileName}.xlsx`;
        this[api].exportDataAsExcel(params);
      }
    },
    handlerReportOpenMarket(value) {
      this.handlerReport(value, 'marketExportParams', '_open-market-analytics', 'marketGridApi');
    },
    handlerReportDeal(value) {
      this.handlerReport(value, 'dealExportParams', '_deal-analytics', 'dealGridApi');
    },
    handlerReportDealOtt(value) {
      this.handlerReport(value, 'dealOttExportParams', '_deal-ott-analytics', 'dealOttGridApi');
    },
    handlerReportMarkets(value) {
      this.handlerReport(value, 'marketsExportParams', '_markets-analytics', 'marketsGridApi');
    },
    handlerReportSkin(value) {
      this.handlerReport(value, 'skinExportParams', '_skin-analytics', 'skinGridApi');
    },
    onCurrencyChange({ name }) {
      this.headers.forEach(col => {
        col.visible = !col.currency || col.currency === name;
      });
      this.resizeColumns();
    },
    // onChangeHeaders(event) {
    //   this.headers = event;
    //   this.resizeColumns();
    // },
    resizeColumns() {
      if (this.dealGridApi && this.marketGridApi) {
        setTimeout(() => {
          this.dealGridApi.sizeColumnsToFit();
          this.marketGridApi.sizeColumnsToFit();
        });
      }
    },
    resetMarketFilters() {
      this.marketGridApi.setFilterModel(null);
    },
    updateMarketActiveFilters() {
      this.hasMarketFilters = !!this.marketGridApi?.isAnyFilterPresent();
    },
    resetDealFilters() {
      this.dealGridApi.setFilterModel(null);
    },
    updateDealActiveFilters() {
      this.hasDealFilters = !!this.dealGridApi?.isAnyFilterPresent();
    },
    resetDealOttFilters() {
      this.dealOttGridApi.setFilterModel(null);
    },
    resetMarketsFilters() {
      this.marketsGridApi.setFilterModel(null);
    },
    resetSkinFilters() {
      this.skinGridApi.setFilterModel(null);
    },
    updateDealOttActiveFilters() {
      this.hasDealOttFilters = !!this.dealOttGridApi?.isAnyFilterPresent();
    },
    updateMarketsActiveFilters() {
      this.hasMarketsFilters = !!this.marketsGridApi?.isAnyFilterPresent();
    },
    updateSkinActiveFilters() {
      this.hasSkinFilters = !!this.skinGridApi?.isAnyFilterPresent();
    },
    selectDateOption(option) {
      if (option === CUSTOM_OPTION) {
        setTimeout(() => this.$refs.datePicker.open(), 0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-alpine.css';

.stats-box {
  @apply bg-white;
}
.breakdown {
  min-width: 280px;
}
.max_width {
  max-width: 280px;
}

::v-deep .date.z-10 {
  @apply z-0;
}
::v-deep #lastChild {
  @apply border-none;
}
::v-deep #filter-box {
  @apply mb-0;
}
:deep(.ag-theme-alpine .ag-root-wrapper) {
  border: 0;
}
:deep(.ag-theme-alpine .ag-header) {
  @apply bg-gray-300 border-0;
}
:deep(.ag-theme-alpine .ag-header-cell) {
  @apply px-1;
}

:deep(.ag-theme-alpine .ag-header-cell-label),
:deep(.ag-theme-alpine .ag-cell-value) {
  @apply text-xs px-1;
}

:deep(.ag-theme-alpine .ag-floating-filter-button) {
  display: none;
}

:deep(.ag-theme-alpine .ag-watermark) {
  opacity: 0 !important;
  display: none !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
  right: auto !important;
  bottom: auto !important;
  visibility: hidden !important;
  transition: none !important;
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../../../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../../../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!ag-grid-community/dist/styles/ag-grid.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../../../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../../../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!ag-grid-community/dist/styles/ag-theme-alpine.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".stats-box[data-v-688333ff] {\n  --bg-opacity: 1;\n  background-color: #fff;\n  background-color: rgba(255, 255, 255, var(--bg-opacity));\n}\n.breakdown[data-v-688333ff] {\n  min-width: 280px;\n}\n.max_width[data-v-688333ff] {\n  max-width: 280px;\n}\n[data-v-688333ff] .date.z-10 {\n  z-index: 0;\n}\n[data-v-688333ff] #lastChild {\n  border-style: none;\n}\n[data-v-688333ff] #filter-box {\n  margin-bottom: 0;\n}\n[data-v-688333ff] .ag-theme-alpine .ag-root-wrapper {\n  border: 0;\n}\n[data-v-688333ff] .ag-theme-alpine .ag-header {\n  --bg-opacity: 1;\n  background-color: #e2e8f0;\n  background-color: rgba(226, 232, 240, var(--bg-opacity));\n  border-width: 0;\n}\n[data-v-688333ff] .ag-theme-alpine .ag-header-cell {\n  padding-left: 0.25rem;\n  padding-right: 0.25rem;\n}\n[data-v-688333ff] .ag-theme-alpine .ag-header-cell-label,[data-v-688333ff] .ag-theme-alpine .ag-cell-value {\n  font-size: 0.75rem;\n  padding-left: 0.25rem;\n  padding-right: 0.25rem;\n}\n[data-v-688333ff] .ag-theme-alpine .ag-floating-filter-button {\n  display: none;\n}\n[data-v-688333ff] .ag-theme-alpine .ag-watermark {\n  opacity: 0 !important;\n  display: none !important;\n  position: absolute !important;\n  top: -9999px !important;\n  left: -9999px !important;\n  right: auto !important;\n  bottom: auto !important;\n  visibility: hidden !important;\n  -webkit-transition: none !important;\n  transition: none !important;\n}", ""]);
// Exports
module.exports = exports;

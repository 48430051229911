var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-section", {
    attrs: { status: _vm.sectionStatus, "is-loading": _vm.isLoadingSection },
    scopedSlots: _vm._u([
      {
        key: "header-right",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c("sun-date-picker-v2", {
                  key: _vm.datepickerKey,
                  ref: "datePicker",
                  attrs: {
                    id: "analytics-filter-date-range",
                    value: _vm.quickFiltersDate,
                    "max-date": _vm.limitDateRange.max,
                    "no-header": "",
                    "no-calendar-icon": "",
                    range: true,
                    "show-date-list": "",
                    "date-list": _vm.dateList,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onQuickFiltersDate($event.value)
                    },
                    "select:date-list-option": _vm.selectDateOption,
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _vm.activeClientId
              ? _c("ingestion-job-notifications", {
                  attrs: {
                    service: _vm.getIngestionJobNotifications,
                    "client-id": _vm.activeClientId,
                  },
                })
              : _vm._e(),
            _c("analytics-section", {
              attrs: {
                header: "Open Market Web",
                "loading-kpis": _vm.isLoadingMarketKpis,
                "kpis-data": _vm.marketkpisData,
                "chart-loading": !_vm.marketChartLoading,
                "chart-options": _vm.chartOptions,
                "chart-series": _vm.marketChartSeries,
                "quick-filters-date": _vm.quickFiltersDate,
                "chart-yaxis": _vm.chartYaxis,
                "chart-type-selected": _vm.chartTypeSelected,
                "chart-revenue": _vm.chartRevenue,
                "has-filters": _vm.hasMarketFilters,
                "report-options": _vm.reportOptions,
                "is-loading-table": _vm.isLoadingMarketTable,
                "table-key": "Deal_OTT_table",
                "table-columns-definition": _vm.tableColumnsDefinition,
                "column-types": _vm.columnTypes,
                "auto-group-column-def": _vm.autoGroupColumnDef,
                "row-model-type": _vm.rowModelType,
                "default-col-def": _vm.defaultColDef,
                "is-loading-totals": _vm.isLoadingMarketTotals,
                "totals-column-names": _vm.totalsColumnNames,
                "totals-column": _vm.totalsColumn,
                totals: _vm.marketTotals,
              },
              on: {
                chartTypeChanged: _vm.onChartTypeChange,
                resetFilters: _vm.resetMarketFilters,
                handlerReport: _vm.handlerReportOpenMarket,
                gridReady: _vm.onMarketGridReady,
                changeFilters: _vm.updateMarketActiveFilters,
              },
            }),
            _c("analytics-section", {
              attrs: {
                header: "Deals WEB",
                "loading-kpis": _vm.isLoadingDealsKpis,
                "kpis-data": _vm.dealsKpisData,
                "chart-loading": !_vm.dealChartLoading,
                "chart-options": _vm.chartOptions,
                "chart-series": _vm.dealChartSeries,
                "quick-filters-date": _vm.quickFiltersDate,
                "chart-yaxis": _vm.chartYaxis,
                "chart-type-selected": _vm.chartTypeSelected,
                "chart-revenue": _vm.chartRevenue,
                "has-filters": _vm.hasDealFilters,
                "report-options": _vm.reportOptions,
                "is-loading-table": _vm.isLoadingDealTable,
                "table-key": "Deal_table",
                "table-columns-definition": _vm.tableColumnsDefinition,
                "column-types": _vm.columnTypes,
                "auto-group-column-def": _vm.autoGroupColumnDef,
                "row-model-type": _vm.rowModelType,
                "default-col-def": _vm.defaultColDef,
                "is-loading-totals": _vm.isLoadingDealTotals,
                "totals-column-names": _vm.totalsColumnNames,
                "totals-column": _vm.totalsColumn,
                totals: _vm.dealTotals,
              },
              on: {
                chartTypeChanged: _vm.onChartTypeChange,
                resetFilters: _vm.resetDealFilters,
                handlerReport: _vm.handlerReportDeal,
                gridReady: _vm.onDealGridReady,
                changeFilters: _vm.updateDealActiveFilters,
              },
            }),
            _c("analytics-section", {
              attrs: {
                header: "Deals OTT",
                "loading-kpis": _vm.isLoadingDealsOttKpis,
                "kpis-data": _vm.dealsOttKpisData,
                "chart-loading": !_vm.dealChartOttLoading,
                "chart-options": _vm.chartOptions,
                "chart-series": _vm.dealOttChartSeries,
                "quick-filters-date": _vm.quickFiltersDate,
                "chart-yaxis": _vm.chartYaxis,
                "chart-type-selected": _vm.chartTypeSelected,
                "chart-revenue": _vm.chartRevenue,
                "has-filters": _vm.hasDealOttFilters,
                "report-options": _vm.reportOptions,
                "is-loading-table": _vm.isLoadingDealOttTable,
                "table-key": "Deal_OTT_table",
                "table-columns-definition": _vm.tableColumnsDefinition,
                "column-types": _vm.columnTypes,
                "auto-group-column-def": _vm.autoGroupColumnDef,
                "row-model-type": _vm.rowModelType,
                "default-col-def": _vm.defaultColDef,
                "is-loading-totals": _vm.isLoadingDealOttTotals,
                "totals-column-names": _vm.totalsColumnNames,
                "totals-column": _vm.totalsColumn,
                totals: _vm.dealOttTotals,
              },
              on: {
                chartTypeChanged: _vm.onChartTypeChange,
                resetFilters: _vm.resetDealOttFilters,
                handlerReport: _vm.handlerReportDealOtt,
                gridReady: _vm.onDealOttGridReady,
                changeFilters: _vm.updateDealOttActiveFilters,
              },
            }),
            _c("analytics-section", {
              attrs: {
                header: "Markets",
                "loading-kpis": _vm.isLoadingMarketsKpis,
                "kpis-data": _vm.marketsKpisData,
                "chart-loading": !_vm.marketsChartLoading,
                "chart-options": _vm.chartOptions,
                "chart-series": _vm.marketsChartSeries,
                "quick-filters-date": _vm.quickFiltersDate,
                "chart-yaxis": _vm.chartYaxis,
                "chart-type-selected": _vm.chartTypeSelected,
                "chart-revenue": _vm.chartRevenue,
                "has-filters": _vm.hasMarketsFilters,
                "report-options": _vm.reportOptions,
                "is-loading-table": _vm.isLoadingMarketsTable,
                "table-key": "Markets_table",
                "table-columns-definition": _vm.tableColumnsDefinition,
                "column-types": _vm.columnTypes,
                "auto-group-column-def": _vm.autoGroupColumnDef,
                "row-model-type": _vm.rowModelType,
                "default-col-def": _vm.defaultColDef,
                "is-loading-totals": _vm.isLoadingMarketsTotals,
                "totals-column-names": _vm.totalsColumnNames,
                "totals-column": _vm.totalsColumn,
                totals: _vm.marketsTotals,
              },
              on: {
                chartTypeChanged: _vm.onChartTypeChange,
                resetFilters: _vm.resetMarketsFilters,
                handlerReport: _vm.handlerReportMarkets,
                gridReady: _vm.onMarketsGridReady,
                changeFilters: _vm.updateMarketsActiveFilters,
              },
            }),
            _c("analytics-section", {
              attrs: {
                header: "Skin",
                "loading-kpis": _vm.isLoadingSkinKpis,
                "kpis-data": _vm.skinKpisData,
                "chart-loading": !_vm.skinChartLoading,
                "chart-options": _vm.chartOptions,
                "chart-series": _vm.skinChartSeries,
                "quick-filters-date": _vm.quickFiltersDate,
                "chart-yaxis": _vm.chartYaxis,
                "chart-type-selected": _vm.chartTypeSelected,
                "chart-revenue": _vm.chartRevenue,
                "has-filters": _vm.hasSkinFilters,
                "report-options": _vm.reportOptions,
                "is-loading-table": _vm.isLoadingSkinTable,
                "table-key": "Skin_table",
                "table-columns-definition": _vm.tableColumnsDefinition,
                "column-types": _vm.columnTypes,
                "auto-group-column-def": _vm.autoGroupColumnDef,
                "row-model-type": _vm.rowModelType,
                "default-col-def": _vm.defaultColDef,
                "is-loading-totals": _vm.isLoadingSkinTotals,
                "totals-column-names": _vm.totalsColumnNames,
                "totals-column": _vm.totalsColumn,
                totals: _vm.skinTotals,
              },
              on: {
                chartTypeChanged: _vm.onChartTypeChange,
                resetFilters: _vm.resetSkinFilters,
                handlerReport: _vm.handlerReportSkin,
                gridReady: _vm.onSkinGridReady,
                changeFilters: _vm.updateSkinActiveFilters,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <router-view>Redirecting...</router-view>
</template>

<script>
import { mapGetters } from 'vuex';
import { USER } from '@/store/modules/auth/keys';
import { ACTIVE_CLIENT, ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import { ROLES } from '@/model/shared/roles';
import { analytics } from '@/router/private/modules/thirdParty/analytics';
import branding from '@/router/private/modules/thirdParty/analytics/branding';
import economics from '@/router/private/modules/thirdParty/analytics/economics';

export default {
  name: 'AnalyticsThirdPartyIndex',
  computed: {
    ...mapGetters({
      user: USER,
      contextActive: ACTIVE_CONTEXT,
      activeClient: ACTIVE_CLIENT,
    }),
    userRole() {
      return this.user.contextRoles.find(role => role.context === this.contextActive.id).role;
    },
    isSellerRole() {
      return this.userRole === ROLES.SELLER.id;
    },
  },
  created() {
    if (this.$route.name === analytics.name) {
      if (this.isSellerRole) {
        this.$router.push({ name: branding.name });
      } else {
        this.$router.push({ name: economics.name });
      }
    } else if (this.$route?.meta?.rolesAccepted?.find(rol => rol === this.userRole)) {
      this.$router.push({ name: this.$route.name });
    }
  },
};
</script>

import { getMonth } from '@sunmedia/sun-ui/src/utils';
import { addDays } from '@/utils/dateTime/addDays';
import FILTERS_CONFIG from '@/model/modules/dashboardSSP/filtersConfig.js';
import SelectOption from '@/model/shared/SelectOption';
import { BREAKDOWN_VALUES } from '@/model/modules/dashboardSSP/analytics/BreakdownValues';
import currency from '@/filters/currency';
import { TODAY } from '@/utils/dateTime/today';
import {
  columnTypes,
  dateList,
  defaultColDef,
  defaultColumns,
  BREAKDOWN_COLUMNS,
  breakdown,
  transformColumnsForAgGrid,
} from '../config';

const breakdownColumns = [
  BREAKDOWN_COLUMNS.NAME,
  BREAKDOWN_COLUMNS.DATE,
  BREAKDOWN_COLUMNS.COUNTRY,
  BREAKDOWN_COLUMNS.PLATFORM,
];

export const CONFIG = Object.freeze({
  breakdown,
  platformType: {
    DEAL: 'DEAL',
    OPEN_MARKET: 'OPEN_MARKET',
    OTT: 'OTT',
    MARKET: 'MARKET',
    SKIN: 'SKIN',
  },
  filters: [FILTERS_CONFIG.DEAL_COUNTRY_NAME, FILTERS_CONFIG.PLATFORM_ID],
  totalsColumn: [
    new SelectOption('impressions', 'Impressions'),
    new SelectOption('clicks', 'Clicks'),
    new SelectOption('requests', 'Requests'),
  ],
  defaultDate: { startDate: getMonth(addDays(TODAY, -1)).startDate, endDate: addDays(TODAY, -1) },
  dateList,
  chartDataType: {
    REVENUE: 'netRevenue',
    REQUEST: 'requests',
    IMPRESSION: 'impressions',
    REVENUEUSD: 'netRevenueUsd',
    BYPLATFORM: 'byPlatform',
  },
  chartOptions: [new SelectOption('netRevenue', 'Net Revenue'), new SelectOption('byPlatform', 'By Platform')],
  yaxis: {
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
      color: '#78909C',
      offsetX: 0,
      offsetY: 0,
    },
  },
  tooltip: {
    y: {
      formatter(val) {
        return val
          .toLocaleString('es-ES', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      },
    },
  },
  chartLabelFormatter: val => {
    if (!val) return null;
    const [year, month, day] = val.split('-');
    return `${day}/${month}/${year}`;
  },
  reportType: { day: 'DEAL_DAY', country: 'ECONOMICS_COUNTRY' },
  currencyOptions: [new SelectOption('eur', 'EUR'), new SelectOption('usd', 'USD')],
  limitMaxDate: addDays(TODAY, -1),
  subBreakdownMixNames: {
    DEAL_DATE: `${BREAKDOWN_VALUES.deal}_${BREAKDOWN_VALUES.date}`,
    DEAL_COUNTRY: `${BREAKDOWN_VALUES.deal}_${BREAKDOWN_VALUES.country}`,
    DEAL_PLATFORM: `${BREAKDOWN_VALUES.deal}_${BREAKDOWN_VALUES.platform}`,
    DATE_DEAL: `${BREAKDOWN_VALUES.date}_${BREAKDOWN_VALUES.deal}`,
    DATE_COUNTRY: `${BREAKDOWN_VALUES.date}_${BREAKDOWN_VALUES.country}`,
    DATE_PLATFORM: `${BREAKDOWN_VALUES.date}_${BREAKDOWN_VALUES.platform}`,
    COUNTRY_DEAL: `${BREAKDOWN_VALUES.country}_${BREAKDOWN_VALUES.deal}`,
    COUNTRY_DATE: `${BREAKDOWN_VALUES.country}_${BREAKDOWN_VALUES.date}`,
    COUNTRY_PLATFORM: `${BREAKDOWN_VALUES.country}_${BREAKDOWN_VALUES.platform}`,
    PLATFORM_DEAL: `${BREAKDOWN_VALUES.platform}_${BREAKDOWN_VALUES.deal}`,
    PLATFORM_DATE: `${BREAKDOWN_VALUES.platform}_${BREAKDOWN_VALUES.date}`,
    PLATFORM_COUNTRY: `${BREAKDOWN_VALUES.platform}_${BREAKDOWN_VALUES.country}`,
  },
  defaultColDef,
  columnTypes,
  marketExportParams: {
    sheetName: 'Open Market Analytics',
    processCellCallback: params => {
      try {
        if (!params.value) return '-';
        return params.value?.currency ? `${currency(params.value.value, params.value.currency)}` : params.value;
      } catch (e) {
        return params.value;
      }
    },
  },
  dealExportParams: {
    sheetName: 'Deal Analytics',
    processCellCallback: params => {
      try {
        if (!params.value) return '-';
        return params.value?.currency ? `${currency(params.value.value, params.value.currency)}` : params.value;
      } catch (e) {
        return params.value;
      }
    },
  },
  dealOttExportParams: {
    sheetName: 'Deal OTT Analytics',
    processCellCallback: params => {
      try {
        if (!params.value) return '-';
        return params.value?.currency ? `${currency(params.value.value, params.value.currency)}` : params.value;
      } catch (e) {
        return params.value;
      }
    },
  },
  marketsExportParams: {
    sheetName: 'Markets Analytics',
    processCellCallback: params => {
      try {
        if (!params.value) return '-';
        return params.value?.currency ? `${currency(params.value.value, params.value.currency)}` : params.value;
      } catch (e) {
        return params.value;
      }
    },
  },
  skinExportParams: {
    sheetName: 'Skin Analytics',
    processCellCallback: params => {
      try {
        if (!params.value) return '-';
        return params.value?.currency ? `${currency(params.value.value, params.value.currency)}` : params.value;
      } catch (e) {
        return params.value;
      }
    },
  },
  headers: defaultColumns.map(transformColumnsForAgGrid),
  breakdown_headers: breakdownColumns.map(transformColumnsForAgGrid),
});

<template>
  <section class="border shadow rounded-lg mb-6 bg-white">
    <div class="bg-white w-full flex p-5 rounded-lg">
      <h2 class="text-2xl font-bold text-gray-700 uppercase">{{ header }}</h2>
    </div>
    <box
      class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 mb-8 rounded-none shadow-none"
    >
      <stats-box title="Net Revenue (Yesterday)" :is-loading="loadingKpis">
        <template v-if="kpisData.yesterday && currencySelected === 0" #value>{{
          kpisData.yesterday.netRevenue | currencyEurFilter
        }}</template>
        <template v-else-if="kpisData.yesterday && currencySelected === 1" #value>{{
          kpisData.yesterday.netRevenueUsd | currencyUsdFilter
        }}</template>
        <template v-else #value>N/A</template>
      </stats-box>
      <stats-box title="Net Revenue (MTD)" :is-loading="loadingKpis">
        <template v-if="kpisData.mtd && currencySelected === 0" #value>{{
          kpisData.mtd.netRevenue | currencyEurFilter
        }}</template>
        <template v-else-if="kpisData.mtd && currencySelected === 1" #value>{{
          kpisData.mtd.netRevenueUsd | currencyUsdFilter
        }}</template>
        <template v-else #value>N/A</template>
      </stats-box>
      <stats-box title="Request (MTD)" :is-loading="loadingKpis">
        <template v-if="kpisData.mtd" #value>{{ kpisData.mtd.requests | numFormatEs }}</template>
        <template v-else #value>N/A</template>
      </stats-box>
    </box>
    <div class="flex mb-5">
      <analytics-chart
        :loading="chartLoading"
        hide-totals
        :chart-options="chartOptions"
        :chart-series="chartSeries"
        :date-start="quickFiltersDate.startDate"
        :date-end="quickFiltersDate.endDate"
        hide-no-data-image
        :yaxis="chartYaxis"
        :default-option-selected="chartTypeSelected"
        @change="onChartTypeChange"
      >
        <template v-if="chartRevenue && currencySelected === 0" #total="{ total }">
          {{ total | currencyEurFilter }}
        </template>
        <template v-else-if="chartRevenue && currencySelected === 1" #total="{ total }">
          {{ total | currencyUsdFilter }}
        </template>
        <template v-else #total="{ total }">
          {{ total | valueToLocalString }}
        </template>
      </analytics-chart>
    </div>
    <div
      class="flex flex-col items-end justify-end w-full p-2 rounded-t-md bg-white lg:flex-row lg:items-center border-t"
    >
      <sun-button
        variant="pill"
        color="orange"
        class="mr-4 px-3 custom-p-1 text-xs bg-gray-700 h-6 flex"
        :class="{ 'hover:bg-gray-800': hasFilters }"
        :disabled="!hasFilters"
        @click="onResetFilters"
      >
        <span class="mr-1">
          <trash-svg class="w-4 inline" />
        </span>
        <span class="whitespace-no-wrap"> Reset filters </span>
      </sun-button>
      <div class="flex flex-row items-center mb-2 ml-5 lg:mb-0">
        <asterix-dropdown
          button-classes="flex hover:bg-gray-300 focus:bg-gray-800 text-gray-700 focus:text-gray-200 rounded h-10 px-4 items-center"
          :disabled="isLoadingTable"
          :options="reportOptions"
          @change="handlerReport"
        >
          <span class="block w-4 mr-2">
            <download-svg />
          </span>
          <template #dropdown-header>
            <p class="p-2 pt-0 text-sm font-bold text-left whitespace-no-wrap">Download as</p>
          </template>
          <template #icon="state">
            <span class="transition-100" :class="!state.visible ? 'rotate-180' : ''">
              <up-svg class="w-2 h-2" :class="state.disabled ? 'text-gray-400' : 'text-gray-500'" />
            </span>
          </template>
        </asterix-dropdown>
      </div>
    </div>
    <div class="flex flex-col w-full mb-8 relative">
      <ag-grid-vue
        :key="tableKey"
        style="height: 500px"
        class="ag-theme-alpine"
        :column-defs="tableColumnsDefinition"
        :column-types="columnTypes"
        pagination
        animate-rows
        :auto-group-column-def="autoGroupColumnDef"
        :row-model-type="rowModelType"
        :scrollbar-width="10"
        :suppress-agg-func-in-header="true"
        :default-col-def="defaultColDef"
        row-group-panel-show
        @grid-ready="emitGridReady"
        @filter-changed="emitChangeFilters"
      />
    </div>
    <analytics-total-loader v-if="isLoadingTotals" :columns="totalsColumnNames" />
    <box v-else-if="totals" class="shadow-none rounded-none">
      <box-column
        v-for="(col, $i) in totalsColumn"
        :key="`box-column-${$i}`"
        :title="col.name"
        :value="totals[col.id] | valueToLocalString"
      />
      <box-column
        v-if="currencySelected === 0"
        title="Net Revenue"
        :value="totals['netRevenue'] | currencyEurFilter(notAvailable, 'es-es')"
      />
      <box-column
        v-else-if="currencySelected === 1"
        title="Net Revenue"
        :value="totals['netRevenueUsd'] | currencyUsdFilter(notAvailable, 'es-es')"
      />
    </box>
  </section>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import Box from '@/components/atoms/Box/Box';
import StatsBox from '@/components/molecules/modules/Analytics/DataDisplay/StatsBox';
import currency from '@/filters/currency';
import AnalyticsChart from '@/components/organisms/shared/asterixCharts/AnalyticsChart';
import AsterixDropdown from '@/components/molecules/shared/AsterixDropdown';
import AnalyticsTotalLoader from '@/components/atoms/Loaders/AnalyticsTotalLoader';
import BoxColumn from '@/components/atoms/Box/BoxColumn';

function thousandSeparator(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export default {
  name: 'AnalyticsSection',
  components: {
    Box,
    StatsBox,
    AnalyticsChart,
    AsterixDropdown,
    AgGridVue,
    AnalyticsTotalLoader,
    BoxColumn,
    DownloadSvg: () => import('@/components/icons/DownloadSvg'),
    UpSvg: () => import('@/components/icons/UpSvg.vue'),
    TrashSvg: () => import('@/components/icons/TrashSvg'),
  },
  filters: {
    currencyEurFilter: value => {
      if (value === null || value === undefined) return 'N/A';
      return thousandSeparator(currency(value, undefined, 'es-es', 2, 'N/A'));
    },
    currencyUsdFilter: value => {
      if (value === null || value === undefined) return 'N/A';
      return thousandSeparator(currency(value, 'USD', 'es-es', 2, 'N/A'));
    },
    valueToLocalString: value => {
      if (value === null || value === undefined) return 'N/A';
      if (value % 1 === 0) {
        return thousandSeparator(value.toLocaleString('es-ES'));
      }
      return thousandSeparator(
        value.toLocaleString('es-ES', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })
      );
    },
  },
  props: {
    header: { type: String, default: () => '' },
    loadingKpis: { type: Boolean, default: () => true },
    chartLoading: { type: Boolean, default: () => true },
    kpisData: { type: Object, default: () => {} },
    currencySelected: { type: Number, default: () => 0 },
    chartOptions: { type: Array, default: () => [] },
    chartSeries: { type: Array, default: () => [] },
    quickFiltersDate: { type: Object, default: () => {}, required: true },
    chartYaxis: { type: Object, default: () => {} },
    chartTypeSelected: { type: String, default: () => '' },
    chartRevenue: { type: Boolean, default: () => true },
    hasFilters: { type: Boolean, default: () => true },
    reportOptions: { type: Array, default: () => [] },
    isLoadingTable: { type: Boolean, default: () => true },
    tableKey: { type: String, default: () => null },
    tableColumnsDefinition: { type: Array, default: () => [] },
    columnTypes: { type: Object, default: () => {} },
    autoGroupColumnDef: { type: Object, default: () => {} },
    rowModelType: { type: String, default: () => '' },
    defaultColDef: { type: Object, default: () => {} },
    isLoadingTotals: { type: Boolean, default: () => true },
    totalsColumnNames: { type: Array, default: () => [] },
    totalsColumn: { type: Array, default: () => [] },
    totals: { type: Object, default: () => {} },
  },
  computed: {
    notAvailable: () => 'N/A',
  },
  methods: {
    onChartTypeChange(event) {
      this.$emit('chartTypeChanged', event);
    },
    onResetFilters(event) {
      this.$emit('resetFilters', event);
    },
    handlerReport({ value }) {
      this.$emit('handlerReport', value);
    },
    emitGridReady(event) {
      this.$emit('gridReady', event);
    },
    emitChangeFilters(event) {
      this.$emit('changeFilters', event);
    },
  },
};
</script>
